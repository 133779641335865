
.hidden {
    visibility: hidden;
}

.not-displayed {
    display: none;
}

.pointer {
    cursor: pointer;
}

// POSITIONING

.flex-container-row {
    display: flex;
    flex-direction: row;
}
.flex-container-column {
    display: flex;
    flex-direction: column;
}
.flex-align-center {
    align-items: center;
}
.flex-justify-space-between {
    justify-content: space-between;
}
.flex-justify-space-around {
    justify-content: space-around;
}
.flex-justify-end {
    justify-content: flex-end;
}
.flex-wrap {
    flex-wrap: wrap;
}

.inline-block {
    display: inline-block;
}

.text-center {
    text-align: center;
}
.text-bold {
    font-weight: bold;
}
.link-underline {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.mt {
    &-xs { margin-top: 0.5rem; }
    &-sm { margin-top: 1rem; }
    &-md { margin-top: 2rem; }
    &-lg { margin-top: 3rem; }
}
.mr {
    &-sm { margin-right: 1rem; }
    &-md { margin-right: 2rem; }
    &-lg { margin-right: 3rem; }
    &-xl { margin-right: 6rem; }
}
.ml {
    &-sm { margin-left: 1rem; }
    &-md { margin-left: 2rem; }
    &-lg { margin-left: 3rem; }
}
.mb {
    &-xs { margin-bottom: 0.5rem; }
    &-sm { margin-bottom: 1rem; }
    &-md { margin-bottom: 2rem; }
    &-lg { margin-bottom: 3rem; }
}

.hide-overflow {
    overflow: hidden;
}

// TEXT

.text-upper {
    text-transform: uppercase;
}
.text-bold {
    font-weight: 700;
}
.text-no-wrap {
    white-space: nowrap;
}

.big-toggle {
    font-size: 36px;
}