// npm run build-core-less
// =====================================================================
// IMPORTS
// =====================================================================

@import 'normalize.less';
@import 'constants.less';
@import 'mixins.less';
@import 'keyframes.less';
@import 'shapes.less';
@import 'common.less';

// =====================================================================
// VARIABLES
// =====================================================================

@panel-font-color: #4D4D4D;
@panel-actionable-font-color: #4D4D4D;
@panel-actionable-sub-header-font-color: #999999;
@placeholder-text-color: #bbbbc5;
@background-color: #E0E0E6;
@login-background-color: white;
@component-loading-background-color: #333333;
@panel-background-color: white;
@panel-actionable-background-color: white;
@close-button-color: white;
@minimize-button-color: white;
@minimize-button-color: #BBBBC5;
@panel-bottom-border-color: #E0E0E6;
@panel-select-border-color: @lightest-gray;
@square-button-color: #0072B2;
@material-icon-color: #0072B2;
@square-button-over-color: #005297;
@keyline-color: #e0e0e6;
@property-name-color: #999999;
@header-logo-url: "../assets/img/sod_logo_horizontal.svg";
@footer-logo-url: "../assets/img/sod_logo_horizontal_gray.svg";
@footer-ds-logo-url: "../assets/img/logoDS.svg";
@linkedin-logo-url: "../assets/img/linkedIn_white.png";
@youtube-logo-url: "../assets/img/youtube_white.png";
@power-button-url: "../assets/img/power-button.svg";
@deepseas-url: "../assets/img/D_black.svg";
@phone-url: "../assets/img/phone.svg";
@exclamation-symbol-url: "../assets/img/sod_logo_horizontal_gray.svg";

@background-color-light: #f2f2f2;
@bulletin-background-color-light: #333333;
@navigation-font-color-light: #0c2577;
@navigation-active-font-color-light: #ef6f00;

// =====================================================================
// GLOBAL STYLES
// =====================================================================

html {
  font-size: 62.5%;
  font-family: @font-family;

  @media (min-width: @extra-large-page-width) {
    font-size: 72%;
  }
}

body {
  font-size: 1.4rem;
  margin: 0;
  background-color: @background-color;
}

button {
  font-family: @font-family;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

h1 {
  font-size: 3.6rem;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 1.2rem;
}

h2 {
  font-size: 2.6rem;
  font-weight: 400;
}

.text-field {
  border-radius: 0.5rem;
  padding: 0 1rem;
  height: 3.6rem;
  border: 0.1rem solid @lightest-gray;
}
textarea.text-field {
  padding: 1rem;
}
.select-field {
  padding: 0 1rem;
  height: 3.8rem;
  border: 0.1rem solid @lightest-gray;
}

.field-container {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
  }
  .count-container {
    display: flex;
    justify-content: space-between;
    .label:last-child {
      color: @gray;
    }
  }
}

.material-icons,
.material-icons-round {
  color: @material-icon-color;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .material-icons-round {
    font-family: "Material Icons" !important;
  }
}

.highlight { background-color: yellow; }

.square-button {
  background-color: @square-button-color;
  color: white;
  border: 0;
  outline: none;
  height: 3.6rem;
  padding: 0 2.4rem;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    background-color: @square-button-over-color;
  }

  &[disabled] {
    opacity: .75;
    cursor: default;
    &:hover {
      background-color: @square-button-color;
    }
  }
  &.secondary {
    background-color: white;
    color: @square-button-color;
    border: 0.1rem solid @square-button-color;
  }
}

.loading-image,
.component-loading-mask:after {
  background-image: url("@{deepseas-url}");
  background-repeat: no-repeat;
  background-position: center;
  height: 2rem;
  width: 2rem;
  background-size: 2rem;
  animation: pulsate 1.3s ease-out;
  animation-iteration-count: infinite;
}

.component-loading-mask {
  &:before {
    content: '';
    position: absolute;
    display: block;
    background-color: @component-loading-background-color;
    height: 100%;
    width: 100%;
    opacity: .7;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    margin: auto;
  }
}

.close-button {
  position: relative;
  display: block;
  height: 3.2rem;
  width: 3.2rem;
  cursor: pointer;
  border: 0;
  box-sizing: border-box;
  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 0.2rem;
    width: 1.6rem;
    top: 1.3rem;
    left: .6rem;
    background: @close-button-color;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.minimize-button {
  position: relative;
  display: block;
  height: 3.2rem;
  width: 3.2rem;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    height: 0.2rem;
    width: 1.7rem;
    top: 1.3rem;
    left: .6rem;
    background: @minimize-button-color;
  }
}

.minimized .minimize-button:after {
  content: '';
  position: absolute;
  height: 1.7rem;
  width: 0.2rem;
  top: 0.6rem;
  left: 1.3rem;
  background: @minimize-button-color;
}

.circle-button {
  position: relative;
  display: block;
  border: .2rem solid @close-button-color;
  background-color: transparent;
  border-radius: 100%;
  cursor: pointer;
}

.image-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.image-container-footer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.linkedin-logo {
  background-image: url("@{linkedin-logo-url}");
}

.footer-ds-logo {
  background-image: url("@{footer-ds-logo-url}");
}

.youtube-logo {
  background-image: url("@{youtube-logo-url}");
}

.phone-logo {
  background-image: url("@{phone-url}");
}

.round-exclamation-symbol {
  background-image: url("@{exclamation-symbol-url}");
}

.threatwatch-logo {
  background-image: url("@{header-logo-url}");
}

.content-container {
  margin: 0 auto;
  width: 100%;
  max-width: @max-page-width;
}

.content {
  min-height: 20rem;
  margin: 0 1rem 9rem 1rem;
}

.panel-wrapper {
  padding-bottom: 2rem;
}

tabbed-panel-header .tabs-container {
  background-color: @panel-background-color;
}
side-list-panel .list-container {
  background-color: @panel-background-color;
}
.panel-container {
  position: relative;
  color: @panel-font-color;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  .panel {
    flex: 100%;
    padding: 1rem;
    background-color: @panel-background-color;
    min-width: 0px;
  }
  .panel-header {
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 1rem;
    border-bottom: .1rem dotted @panel-bottom-border-color;
    .circle-button {
      position: absolute;
      top: -0.6rem;
      right: 0rem;
      border-color: @minimize-button-color;
      &:before,
      &:after {
        background-color: @minimize-button-color;
      }
    }
  }
  .panel-content-container {
    position: relative;
  }
  .panel-body-container {
    padding-top: 1rem;
  }
  .panel-body {
    overflow-y: hidden;
  }
  .updating-title {
    opacity: 0.3;
  }
  &.actionable {
    color: @panel-actionable-font-color;
    .panel {
      background-color: @panel-actionable-background-color;
    }
    .panel-body-container {
      padding: 0;
    }
    .panel-header {
      border-bottom: none;
      text-transform: inherit;
      font-weight: normal;
      font-size: 2rem;
      padding-bottom: 2rem;
      span {
        display: block;
        color: @panel-actionable-sub-header-font-color;
        font-weight: 700;
      }
      .circle-button {
        top: -0.2rem;
      }
    }
    .panel-title {
      display: inline-block;
      margin-right: 3.8rem;
    }
    .minimize-button {
      position: absolute;
      top: 0.8rem;
      right: 0;
      border-color: @minimize-button-color;
      &:before,
      &:after {
        background-color: @minimize-button-color;
      }
    }
  }
  &.collapsible {
    max-height: 999rem;
    transition: max-height 0.5s;
    overflow: visible;
    &.minimized {
      max-height: 6.6rem;
      overflow: hidden;
    }
  }
}

.panel-select {
  position: relative;
  display: inline-block;
  border: .1rem solid @panel-select-border-color;
  font-size: 1.4rem;
  select {
    padding: 1.1rem 0.7rem;
    background-color: white;
    color: @panel-actionable-font-color;
    margin: 0;
    border: 0;
    border-radius: 0;
    outline: none;
    font-weight: 700;
    height: 3.8rem;
    width: 100%;
    padding-right: 2.7rem;
    .appearance(none);
    &::-ms-expand {
      display: none;
    }
    &+.chevron-icon {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
    }
    &:invalid {
      color: @placeholder-text-color;
      option {
          color: initial;
      }
    }
    &:disabled { opacity: 0.3; }
    option:disabled { // TODO for ID
      display: none;
      visibility: hidden;
    }
  }
  &:after {
    content: '<';
    font-family: Consolas, monospace;
    font-size: 1.8rem;
    pointer-events: none;
    position: absolute;
    transform: rotate(-90deg);
    top: 0.8rem;
    right: 1.2rem;
  }
  .panel-select-placeholder {
    position: absolute;
    top: 1rem;
    left: 1rem;
    pointer-events: none;;
    color: @placeholder-text-color;
  }
  select.ng-invalid + .panel-select-placeholder,
  select[required].ng-untouched:not(.ng-valid):not(.set) + .panel-select-placeholder {
      visibility: visible;
  }
  select.ng-invalid.ng-touched {
      border: 0.1rem solid red;
      height: 3.6rem;
  }
}

.login-route {
  background-color: @login-background-color;
  height: 100vh;
  min-height: 27.6rem;
  overflow: hidden;
  .content {
    margin-bottom: 0;
    min-height: inherit;
  }
}

.detail-summary {
  font-weight: 700;
}

.detail-summary-header {
  text-transform: uppercase;
  font-size: 2.2rem;
}

.detail-summary-description {
  max-width: 53rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 4rem;
  word-wrap: break-word;
  word-break: break-word;
}

.detail-properties {
  display: block;
  font-size: 1.3rem;
}

.detail-property-container {
  padding-bottom: 4rem;
  width: 100%;
}

.detail-header {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
}

.detail-property {
  border-bottom: .1rem solid @keyline-color;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.detail-name {
  color: @property-name-color;
}

.detail-value {
  float: right;
  font-weight: 700;
}

.modal {
  .detail-property {
    display: flex;
    justify-content: space-between;
  }
  .detail-name {
    padding-right: 1rem;
  }
}

.report-type.active {
  color: @red  !important;
  border-bottom-color: @red  !important;
}

.checkbox-container {
  border: 1px solid @lightest-gray;
  overflow-y: auto;
  box-sizing: border-box;
  ul {
    margin: 0;
    padding: 1rem;
    list-style: none;
  }
  li {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    &:first-child {
      padding-top: 1rem;
    }
    &:hover {
      cursor: pointer; 
    }
  }
  span {
		vertical-align: middle;
		pointer-events: none;
  }
  .checkbox {
    display: inline-block;
    position: relative;
    height: 2rem;
    width: 2rem;
    margin-right: 0.8rem;
    border: 0.1rem solid @dark-gray;
    &.selected {
      background-color: @square-button-color;
      &:before,
      &:after {
        content: '';
        display: block;
        background-color: white;
        position: absolute;
      }
      &:before {
        height: 0.9rem;
        width: 0.4rem;
        left: 0.4rem;
        bottom: 0.3rem;
        transform: rotate(-45deg);
      }
      &:after {
        height: 1.4rem;
        width: 0.4rem;
        left: 1.0rem;
        bottom: 0.3rem;
        transform: rotate(45deg);
      }
    }
  }
}

modal .checkbox-container {
  height: 100%;
}

.margin-left-md {
  margin-left: 2rem;
}

body.light {
  background-color: #f2f2f2;
  bulletin section {
    background-color: @darkest-gray;
  }
  nav {
    a, label {
      color: @navigation-font-color-light;
    }
    > nav-tree > ul > li > a.active,
    > nav-tree > ul > li:hover > a {
      color: @navigation-font-color-light;
    }
    > nav-tree > ul > li:not(:hover) > a.active {
        color: @navigation-active-font-color-light;
    }
  }
  footer {
    .threatwatch-logo {
      display: none;
    }
    .social-container .image-container {
      filter: brightness(0%) invert(100%);
    }
  }
  .report-type.active {
    color: @navigation-active-font-color-light;
    border-bottom-color: @navigation-active-font-color-light;
  }
}

//======================================================================
// LARGE FORM FACTOR STYLES
// =====================================================================

@media (min-width: @media-min-large-width) {
  .panel-container {
    &.collapsible {
      max-height: 60rem;
    }
    .panel {
      flex: 1;
      padding: 2rem;
    }
    .panel-header {
      font-size: 1.7rem;
      padding-bottom: 1.8rem;
      + .panel-body-container {
        padding-top: 2rem;
      }
    }
    .panel-body-container {
      overflow-x: hidden;
      overflow-y: hidden;
    }
    &.actionable {
      &.collapsible {
        max-height: 999rem; // used for CSS transition animation
        &.minimized {
          max-height: 6.8rem;
        }
      }
      .panel-body-container {
        padding: 0;
      }
      .panel-header {
        font-size: 2.5rem;
        padding-bottom: 2rem;
        span {
          display: inline;
        }
      }
      .minimize-button {
        top: 0;
      }
    }
  }
  .detail-properties {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 64.2rem;
  }
  .detail-property-container {
    padding-bottom: 2.8rem;
    width: 48%;
    flex: 0 1 auto;
  }
}

@media (min-width: @extended-page-width) {
  .content {
    margin: 0 4rem 9rem;
  }
}

.over-cell {
  overflow: inherit !important;
  transition: all .3s 0s ease;
}

.ag-header-cell-label .ag-header-icon.ag-sort-order {
  display: none
}

.logo-container {
  padding: 1rem;
  border-radius: 1rem;
}

span.chip {
  
  padding: 2%;
  border-radius: 6px;
  width: auto;
  display: inline-block;
  text-align: center;
  min-width: 130px;
}

.beyond {
  background: #E10600;
  color: white;
}

.within {
  background: #00e183;
  color: black;
}

@import 'dark-mode.less';
